<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultHeader2 :routeBack="'/Tenders'" :titleHeader="'Actualizar documento'" />
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <section class="content-form">
                        <div class="content-inputs" id="content-inputs">
                            <label for="number_process">Número de proceso *</label>
                            <input v-model="dataTender.number_proccess" class="input" name="number_process" type="text"
                                placeholder="Número de proceso" id="number_process" required>
                        </div>

                        <div class="content-inputs" id="content-inputs">
                            <label for="type_process">Tipo de proceso *</label>
                            <input v-model="dataTender.type_proccess" class="input" name="type_process" type="text"
                                placeholder="Tipo de proceso" id="type_process" required>
                        </div>

                        <div class="content-inputs" id="content-inputs">
                            <label for="status">Estado *</label>
                            <input v-model="dataTender.status" class="input" name="status" type="text" placeholder="Estado"
                                id="status" required>
                        </div>
                        <div class="content-inputs" id="content-inputs">
                            <label for="entity">
                                Entidad *
                            </label>
                            <input v-model="dataTender.entity" class="input" name="entity" type="text" placeholder="Entidad"
                                id="entity" required>
                        </div>
                        <div class="content-inputs" id="content-inputs">
                            <label for="type_object">Tipo objeto *</label>
                            <input id="type_object" v-model="dataTender.typeObject" class="input" name="type_object"
                                type="text" placeholder="Tipo objeto" required>
                        </div>
                        <div class="content-inputs" id="content-inputs">
                            <label for="cuantia">Cuantia *</label>
                            <input id="cuantia" v-model="dataTender.cuantia" class="input" name="cuantia" type="text"
                                placeholder="Cuantia" required>
                        </div>

                        <div class="content-inputs" id="content-inputs">
                            <label for="date_licitacion">Fecha *</label>
                            <input id="date_licitacion" v-model="dataTender.date" class="input" name="date_licitacion"
                                type="date" placeholder="fecha" required>
                        </div>

                    </section>
                    <hr>
                    <!-- section-anexos -->
                    <section class="sec-anexos" id="sec-anexos">
                        <div class="header-sec-anexos">
                            <h1 class="title-section-anexos">Subir anexos de las Invitaciones Publicas
</h1>
                            <i class="fa-solid fa-circle-info"></i>
                        </div>

                        <div class="content-list-items-add">
                            <div v-for="(item, index) in listItemAnexo" :key="index">
                                <div class="content-item">
                                    <h2 class="name-item-added">{{ item.name }}</h2>
                                    <i class="el-icon-close text-danger icon-added"
                                        @click="showConfirmation(item, dataTender._id, item._idName)"></i>
                                </div>
                            </div>
                        </div>

                        <div class="progress" v-if="showProgrees">{{ uploadProgress.toFixed(2) }}%</div>

                        <div class="content-form">
                            <div class="content-inputs" id="content-inputs">
                                <label class="label" id="label" for="name">Nombre *</label>
                                <input id="name" v-model="dataItemAnexo.name" class="input input-anexo" name="name"
                                    type="text" placeholder="Nombre" required>
                            </div>
                            <div class="content-inputs" id="content-inputs">
                                <label class="label" id="label" for="description">Descripción *</label>
                                <input id="description" v-model="dataItemAnexo.description" class="input input-anexo"
                                    name="description" type="text" placeholder="Descripción" required>
                            </div>

                            <div class="content-inputs" id="content-inputs">
                                <label class="label" id="label" for="date_publication">Fecha de publicación *</label>
                                <input id="date_publication" v-model="dataItemAnexo.datePublication"
                                    class="input input-anexo" name="date_publication" type="date"
                                    placeholder="Feca de publicación" required>
                            </div>
                            <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()" name="file-5"
                                class="inputfile inputfile-5 input-anexo" required />
                            <label id="label" for="file-5" class="label-file label">
                                <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20" height="17"
                                        viewBox="0 0 20 17">
                                        <path
                                            d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                        </path>
                                    </svg>
                                </figure>
                            </label>
                            <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar archivo *</h6>
                        </div>
                        <button class="btn-save-item" @click="addItemAnexoToArray()">Añadir anexo</button>
                    </section>
                    <hr>

                    <button class="btn-save-data" @click="updateInfoToDatebase()">Actualizar
                        licitación</button>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault
    },
    data() {
        return {
            listItemAnexo: [],
            _idParam: "",
            isLoading: false,
            selectedFile: File,
            uploadProgress: 0,
            showProgrees: false,

            dataItemAnexo: {
                name: "",
                description: "",
                datePublication: "",
                urlPdf: ""
            },

            dataTender: {
                number_proccess: "",
                type_proccess: "",
                status: "",
                entity: "",
                typeObject: "",
                cuantia: "",
                date: "",
                anexos: this.listItemAnexo
            }
        }
    },
    mounted() {
        this._idParam = this.$route.params._id
        this.getInfoTender(this._idParam)
    },
    methods: {
        async getInfoTender(_idDoc) {
            this.isLoading = true
            await db.collection('tenders')
                .doc(_idDoc)
                .get().then((querySnapshot) => {
                    this.dataTender = querySnapshot.data()
                    this.listItemAnexo = querySnapshot.data().anexos
                    this.isLoading = false
                })
        },

        async updateInfoToDatebase() {
            this.isLoading = true
            if (this.validaFormFirtForm()) {
                await db.collection('tenders').doc(this.dataTender._id).set(this.dataTender)
                this.$swal
                    .fire({
                        title: 'Anexo actualizado',
                        text: 'Licitación actualizada correctamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                this.isLoading = false
            }
        },

        async addItemAnexoToArray() {
            if (this.validaFormSecondForm()) {
                const storageRef = storage.ref();
                const pdfRef = storageRef
                    .child('pdfsAnexosTender')
                    .child(this.dataTender._id)
                    .child(this.dataItemAnexo.name);
                const reader = new FileReader();

                reader.onload = async () => {
                    const pdfDataURL = reader.result;
                    await pdfRef.putString(pdfDataURL, 'data_url');
                    const pdfUrl = await pdfRef.getDownloadURL();
                    this.dataItemAnexo._idName = this.dataItemAnexo.name;
                    this.dataItemAnexo.urlPdf = pdfUrl;
                    this.listItemAnexo.push(this.dataItemAnexo);
                    this.dataTender.anexos = this.listItemAnexo;
                    db.collection('tenders')
                        .doc(this._idParam)
                        .update({ anexos: this.dataTender.anexos });
                    this.dataItemAnexo = {};
                };

                reader.readAsDataURL(this.selectedFile);
            }
        },
        navToIndexTender() { this.$router.push('/AdminDashboard/IndexTender') },

        loadFilePdf() {
            const input = document.getElementById('file-5')
            input.accept = "application/pdf";
            input?.addEventListener('change', (e) => {
                const target = e.target
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                } else {
                    alert("No seleccionó ningún archivo")
                }
            });
        },

        showConfirmation(itemAnexo, childOne, childTwo) {
            this.$swal
                .fire({
                    title: 'Eliminar anexo',
                    text: '¿Estás seguro de que deseas realizar esta acción? Al aceptar se eliminará direactamente de la base de datos',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.removeItemAnexoToArray(itemAnexo, childOne, childTwo);
                });
        },

        async removeItemAnexoToArray(itemAnexo, childOne, childTwo) {
            const index = this.listItemAnexo.indexOf(itemAnexo);
            if (index === -1) { return; }
            this.listItemAnexo.splice(index, 1);
            this.isLoading = true
            try {
                await this.deletePdf(childOne, childTwo);
                const docRef = db.collection('tenders').doc(childOne);
                const doc = await docRef.get();
                if (doc.exists) { return; }
                const anexos = doc.data().anexos || [];
                const updatedAnexos = anexos.filter((anexo) => anexo.name == childTwo);
                await docRef.update({ anexos: updatedAnexos });
                this.$swal
                    .fire({
                        title: 'Anexo eliminado',
                        text: 'Se ha eliminado el anexo correctamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                this.isLoading = false
            } catch (error) {
                this.$swal
                    .fire({
                        title: 'Error',
                        text: 'Ha ocurrido un error al eliminar el anexo, vuelve a intentarlo',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                this.isLoading = false
            }
        },

        async deletePdf(childOne, childTwo) {
            const storageRef = storage.ref()
            const pdfRef = storageRef.child('pdfsAnexosTender')
                .child(childOne)
                .child(childTwo)
            try {
                await pdfRef.delete()
            } catch (error) {
            }
        },

        validaFormFirtForm() {
            const inputs = document.getElementsByTagName('input');
            const label = document.getElementsByTagName('label');

            for (let i = 0; i < inputs.length - 4; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    label[i].style.color = ''
                }
            }
            return true;
        },

        validaFormSecondForm() {
            const inputs = document.querySelectorAll('.input-anexo');
            const labels = document.querySelectorAll('.label');
            const elementSelectPdf = document.getElementById('name-pdf-selected')

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    elementSelectPdf.style.color = "red"
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    labels[i].style.color = ''
                    elementSelectPdf.style.color = ''
                }
            }
            return true;
        }
    },
}
</script>